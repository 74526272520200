import React from 'react';
import creation from "../assets/images/creation-velo-atelier.jpg";

export const Soon = () => {
  return (
    <div className="__front_box__ p-5 text-center">
      <h2 className="h2 mt-3 mb-5">En construction ...</h2>
      <img src={creation} className="w-50 img-fluid img-thumbnail" alt="logo"/>
    </div>
  );
}
