import React from 'react';
import {BoxTitle} from "../../components/front/BoxTitle";
import {Reglement} from "../../components/front/Reglement";
import pdf from "../../assets/images/pdf.png";

export const Licence = () => {
  return (
    <>
      <div className="__front_box__">
        <BoxTitle title="Licence 2024 / 2025"/>

        <div className="font-1_25 text-center mb-5">
          Documents à remplir et à renvoyer à Christian (adresse sur le bulletin d'adhésion)
        </div>
        <div className="mb-5 d-flex justify-content-around text-center">
          <p className="col text-center">
            <a className="ms-3" href="/download/adhesion-2024-2025.pdf" download="adhesion-2024-2025.pdf"><img src={pdf} className="img-fluid max-w50px" alt="pdf"/></a>
            <br/>
            <u>Bulletin d’adhésion</u>
          </p>
          <p className="col text-center">
            <a className="ms-3" href="/download/reglement_interieur-charte_ethique.pdf" download="reglement_interieur-charte_ethique.pdf"><img src={pdf} className="img-fluid max-w50px" alt="pdf"/></a>
            <br/>
            <u>Règlement interieur</u>
          </p>
        </div>
        <div className="mb-5 d-flex justify-content-around text-center">
          <p className="col text-center">
            <a className="ms-3" href="/download/certificat-medical.pdf" download="certificat-medical.pdf"><img src={pdf} className="img-fluid max-w50px" alt="pdf"/></a>
            <br/>
            Certificat médical
          </p>
          <p className="col text-center">
            <a className="ms-3" href="/download/attestation-reponse.pdf" download="attestation-reponse.pdf"><img src={pdf} className="img-fluid max-w50px" alt="pdf"/></a>
            <br/>
            Attestation de réponse et Questionnaire de santé
          </p>
          <p className="col text-center">
            <a className="ms-3" href="/download/autorisation-parentale.pdf" download="autorisation-parentale.pdf"><img src={pdf} className="img-fluid max-w50px" alt="pdf"/></a>
            <br/>
            Autorisation parentale
          </p>
        </div>
        <div className="font-1_25 text-center">
          <i className="fa fa-warning me-1" />
          <u>ATTENTION</u> :
          si vous n'avez pas renouvellé votre licence au plus tard le 30 septembre 2024<br/>
          vous n'êtes plus couvert par l'assurance.
        </div>
      </div>

      <div className="mt-4">
        <Reglement />
      </div>
    </>
  );
}
