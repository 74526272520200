import React, {useState} from 'react';
import {NavLink} from "react-router-dom";

export const Header = () => {
  const [show, setShow] = useState(false);

  const navigate = () => {
    setShow(false);
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  return (
    <>
      <h1 className="d-none d-md-block __front_h1__"><NavLink to="/" title="C’Team Chartres">C’Team Chartres</NavLink></h1>
      <div className="__header_nav__ bg-primary mb-4">
        <div className="__front_navbar_container__ container">
          <div className=" d-flex d-md-none justify-content-between">
            <h2 className="__front_h2__"><NavLink to="/" title="C’Team Chartres" onClick={() => navigate()}>C’Team Chartres</NavLink></h2>
            <p className="mt-auto mb-auto p-0">
              <button className="btn btn-sm __front_h2_btn__" onClick={() => setShow(!show)}>
                <i className="fa-solid fa-bars"></i>
              </button>
            </p>
          </div>
          {show && (
            <div className="d-flex d-md-none flex-column __front_navbar__">
              <NavLink to="/parcours" title="Parcours" onClick={() => navigate()}>Parcours</NavLink>
              <NavLink to="/calendrier" title="Calendrier" onClick={() => navigate()}>Calendrier</NavLink>
              <NavLink to="/equipement" title="Equipement" onClick={() => navigate()}>Equipement</NavLink>
              <NavLink to="/licence" title="Licence" onClick={() => navigate()}>Licence</NavLink>
              <NavLink to="/contact" title="Nous contacter" onClick={() => navigate()}>Contact</NavLink>
              <NavLink to="/photo" onClick={() => navigate()}>Photos</NavLink>
              <a href="https://www.facebook.com/teamchartrescyclo/" target="_blank" rel="noreferrer">Facebook</a>
            </div>
          )}
          <div className="d-none d-md-flex justify-content-between __front_navbar__">
            <NavLink to="/parcours" title="Parcours" onClick={() => navigate()}>Parcours</NavLink>
            <NavLink to="/calendrier" title="Calendrier" onClick={() => navigate()}>Calendrier</NavLink>
            <NavLink to="/equipement" title="Equipement" onClick={() => navigate()}>Equipement</NavLink>
            <NavLink to="/licence" title="Licence" onClick={() => navigate()}>Licence</NavLink>
            <NavLink to="/contact" title="Nous contacter" onClick={() => navigate()}>Contact</NavLink>
            <NavLink to="/photo" onClick={() => navigate()}>Photos</NavLink>
            <a href="https://www.facebook.com/teamchartrescyclo/" target="_blank" rel="noreferrer">Facebook</a>
          </div>
        </div>
      </div>
    </>
  );
}
