import React from 'react';
import {useAppSelector} from "../redux/store";
import {Login} from "./auth/Login";
import {Outlet} from "react-router-dom";
import {Header} from "../components/admin/Header";

export const Admin = () => {
  const accessToken: string | undefined = useAppSelector((state) => state.auth.accessToken);
  const refresh: boolean = useAppSelector((state) => state.auth.refresh);

  return (
    <>
      {accessToken === undefined && (
        <Login/>
      )}

      {accessToken !== undefined && ! refresh && (
        <>
          <Header/>
          <Outlet/>
        </>
      )}
    </>
  );
}
