import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiQueryParam, ApiService} from "../service/ApiService";
import {RootState} from "./store";
import {equipmentResultCollectionType, equipmentResultType} from "../types/ApiResult/EquipmentResultType";
import {equipmentHydrator} from "../models/hydrator/equipmentHydrator";
import {equipment} from "../models/equipment";

export const fetchData = createAsyncThunk('get-equipment', async(_, thunkAPI): Promise<any> => {
  const state: state = (thunkAPI.getState() as RootState).equipment;

  if (state.init > 0) {
    return undefined;
  }

  const apiFilters: ApiQueryParam = new ApiQueryParam();

  apiFilters.addSort({
    by: 'label',
    direction: 'ASC'
  });

  apiFilters.setCount(50);

  const result: equipmentResultCollectionType =  await ApiService.get('/equipment', apiFilters) as equipmentResultCollectionType;

  return {
    paginate: {
      page: result._page,
      pageCount: result._page_count,
      total: result._total_items
    },
    data: result._embedded.equipments.map((equipment: equipmentResultType) => {
      return equipmentHydrator.hydrate(equipment);
    })
  };
})

interface state {
  init: number;
  loading: boolean;
  paginate?: {
    page: number;
    pageCount: number;
    total: number;
  }
  data: equipment[]
}

const initialState: state = {
  init: 0,
  loading: false,
  paginate: undefined,
  data: []
}

export const equipmentSlice = createSlice({
  name: "equipment",
  initialState,
  reducers: {},
  extraReducers: (builder): void => {
    builder.addCase(fetchData.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.paginate = action.payload.paginate;
        state.data = action.payload.data;
        state.init = Date.now();
      }

      state.loading = false;
    })
    builder.addCase(fetchData.rejected, (state) => {
      state.loading = false;
    })
  }
});

export default equipmentSlice.reducer;