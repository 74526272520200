import React, {FormEvent, useState} from 'react';
import {BoxTitle} from "./BoxTitle";
import {Map} from "../Map";
import {SendMailService} from "../../service/SendMailService";
import {WaitService} from "../../service/WaitService";

type NousContacterProp = {
  className?: string;
}

export const NousContacter = ({className}: NousContacterProp) => {
  const [submited, setSubmited] = useState(false);
  const [sended, setSended] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [object, setObject] = useState('');
  const [message, setMessage] = useState('');

  // region form
  const initForm = () => {
    setSubmited(false);
    setName('');
    setEmail('');
    setObject('');
    setMessage('');
  }

  const submit = (e: FormEvent) => {
    e.preventDefault();
    setSubmited(true);

    if (valid()) {
      SendMailService.send({
        name: name,
        email: email,
        object: object,
        message: message
      }).then(() => {
        initForm();
        setSended(true);
        WaitService.delay(60 * 1000).then(() => setSended(false)) // 1min
      });
    }
  }

  const validName = (): boolean => {
    return name.replaceAll(' ', '').length > 0 && name.length < 256;
  }

  const validEmail = (): boolean => {
    return email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i) !== null;
  }

  const validObject = (): boolean => {
    return object.replaceAll(' ', '').length > 0 && object.length < 256;
  }

  const validMessage = (): boolean => {
    return message.replaceAll(' ', '').length > 0;
  }

  const valid = (): boolean => {
    return validName() && validEmail() && validObject() && validMessage();
  }
  // endregion

  return (
    <div className={`${className} __front_box__`}>
      <BoxTitle title="Nous contacter"/>

      <div className="row">
        <div className="d-none d-md-block col-md-5">
          <Map
            position={{lat: 48.435774, lng: 1.479797}}
            size={{width: '100%', height: '400px'}}
            zoom={14}
            markers={[{lat: 48.435774, lng: 1.479797}]}
          />
        </div>
        <div className="col-12 col-md-7">
          {sended && (
            <div className="bg-success-subtle p-2 border-radius-0_4">
              Votre message a été envoyé avec succès.
            </div>
          )}
          {!sended && (
            <form onSubmit={(e: FormEvent) => submit(e)}>
              <div className="mb-2">
                <label htmlFor="name" className="form-label m-0 bold">Nom :</label>
                <input id="name"
                       type="text"
                       className={`form-control ${submited ? (validName() ? 'is-valid' : 'is-invalid') : ''}`}
                       value={name}
                       onChange={(e) => setName(e.target.value)}/>
                <div id="objetFeedback" className="invalid-feedback">
                  Veuillez saisir votre nom (255 caractères maximum).
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label m-0 bold">Email :</label>
                <input id="email"
                       type="email"
                       className={`form-control ${submited ? (validEmail() ? 'is-valid' : 'is-invalid') : ''}`}
                       value={email}
                       onChange={(e) => setEmail(e.target.value)}/>
                <div id="objetFeedback" className="invalid-feedback">
                  Veuillez saisir un email valide.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="objet" className="form-label m-0 bold">Objet :</label>
                <input id="objet"
                       type="text"
                       className={`form-control ${submited ? (validObject() ? 'is-valid' : 'is-invalid') : ''}`}
                       value={object}
                       onChange={(e) => setObject(e.target.value)}/>
                <div id="objetFeedback" className="invalid-feedback">
                  Veuillez saisir l'objet du message (255 caractères maximum).
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label m-0 bold">Message :</label>
                <textarea id="message"
                          className={`form-control ${submited ? (validMessage() ? 'is-valid' : 'is-invalid') : ''}`}
                          rows={5}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}/>
                <div id="messageFeedback" className="invalid-feedback">
                  Veuillez saisir votre message.
                </div>
              </div>
              <div className="mb-3">
                <button className="btn btn-sm btn-primary" type="submit">Envoyer</button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
