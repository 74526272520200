import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AuthService} from "../service/AuthService";

export const login = createAsyncThunk('auth/login', async (credentials: { username: string; password: string }, thunkAPI): Promise<any> => {
  return AuthService.loginByUsernamePassword(credentials.username, credentials.password);
})

export const loginByRefresh = createAsyncThunk('auth/refresh', async (_, thunkAPI): Promise<any> => {
  return AuthService.loginByRefreshToken();
})

interface state {
  loading: boolean,
  refresh: boolean,
  accessToken?: string,
}

const initialState: state = {
  loading: false,
  refresh: false,
  accessToken: undefined
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder): void => {
    builder
      // Auth
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.accessToken = action.payload;
        state.loading = false;
      })
      .addCase(login.rejected, (state) => {
        state.loading = false;
      })

      // Refresh
      .addCase(loginByRefresh.pending, (state) => {
        state.refresh = true;
      })
      .addCase(loginByRefresh.fulfilled, (state, action) => {
        state.accessToken = action.payload;
        state.refresh = false;
      })
      .addCase(loginByRefresh.rejected, (state) => {
        state.refresh = false;
      })
  }
});

export default authSlice.reducer;