import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiQueryParam, ApiService} from "../service/ApiService";
import {RootState} from "./store";
import {albumResultCollectionType, albumResultType} from "../types/ApiResult/AlbumResultType";
import {albumHydrator} from "../models/hydrator/albumHydrator";
import {album} from "../models/album";

export const fetchData = createAsyncThunk('get-album', async(_, thunkAPI): Promise<any> => {
  const state: state = (thunkAPI.getState() as RootState).album;

  if (state.init > 0) {
    return undefined;
  }

  const apiFilters: ApiQueryParam = new ApiQueryParam();

  apiFilters.addSort({
    by: 'label',
    direction: 'ASC'
  });

  apiFilters.setCount(0);

  const result: albumResultCollectionType =  await ApiService.get('/album', apiFilters) as albumResultCollectionType;

  return {
    paginate: {
      page: result._page,
      pageCount: result._page_count,
      total: result._total_items
    },
    data: result._embedded.albums.map((album: albumResultType) => {
      return albumHydrator.hydrate(album);
    })
  };
})

interface state {
  init: number;
  loading: boolean;
  paginate?: {
    page: number;
    pageCount: number;
    total: number;
  }
  data: album[]
}

const initialState: state = {
  init: 0,
  loading: false,
  paginate: undefined,
  data: []
}

export const albumSlice = createSlice({
  name: "album",
  initialState,
  reducers: {},
  extraReducers: (builder): void => {
    builder.addCase(fetchData.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.paginate = action.payload.paginate;
        state.data = action.payload.data;
        state.init = Date.now();
      }

      state.loading = false;
    })
    builder.addCase(fetchData.rejected, (state) => {
      state.loading = false;
    })
  }
});

export default albumSlice.reducer;