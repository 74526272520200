import React, {useEffect, useState} from 'react';
import {equipementFiltersType} from "../../../pages/front/Equipement";
import {collection} from "../../../models/collection";
import {equipment} from "../../../models/equipment";
import {size} from "../../../models/size";
import {I18nService} from "../../../service/I18nService";

type EquipementFiltreProps = {
  equipments: equipment[];
  setFilters: (f: equipementFiltersType) => void;
}

export const EquipementFiltre = ({equipments, setFilters}: EquipementFiltreProps) => {
  const [collections, initCollections] = useState([] as collection[]);
  const [sizes, initSizes] = useState([] as size[]);
  const [types, initTypes] = useState([] as string[]);

  const [typeSelected, setType] = useState([] as string[]);
  const [collectionSelected, setCollection] = useState([] as string[]);
  const [sizeSelected, setSize] = useState([] as string[]);

  const handleSelect = (value: string, array: string[], callback: (v: string[]) => any) => {
    const index: number = array.indexOf(value)

    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(value);
    }

    callback(array);
  }

  useEffect(() => {
    setFilters({
      type: typeSelected,
      collection: collectionSelected,
      size: sizeSelected
    });
  }, [typeSelected, collectionSelected, sizeSelected]);

  useEffect(() => {
    const collections: collection[] = [];
    const sizes: size[] = [];
    const types: string[] = [];

    equipments.forEach((equipment: equipment) => {
      if (equipment.type && ! types.includes(equipment.type)) {
        types.push(equipment.type)
      }

      if (-1 === collections.findIndex((collection: collection) => {
        return collection.collectionId === equipment.collection.collectionId;
      })) {
        collections.push(equipment.collection)
      }

      equipment.sizes?.forEach((size: size) => {
        if (-1 === sizes.findIndex((s: size) => {
          return s.sizeId === size.sizeId;
        })) {
          sizes.push(size)
        }
      })
    });

    initCollections(collections);
    initTypes(types);
    initSizes(sizes);

  }, [equipments]);

  return (
    <form onSubmit={(e: React.FormEvent) => e.preventDefault()} className="text-center">
      <div className="d-inline-flex btn-group m-2">
        {collections.map((collection: collection, k: number) => (
          <button key={k} className={`btn btn-xs __filtre_btn__ ${collectionSelected.includes(collection.label) ? '__filtre_actif__' : ''}`}
                  onClick={() => handleSelect(collection.label, [...collectionSelected], ((v: string[]) => setCollection(v)))}
          >{collection.label}</button>
        ))}
      </div>
      <div className="d-inline-flex btn-group m-2">
        {sizes.map((size: size, k: number) => (
          <button key={k} className={`btn btn-xs __filtre_btn__ ${sizeSelected.includes(size.label) ? '__filtre_actif__' : ''}`}
                  onClick={() => handleSelect(size.label, [...sizeSelected], ((v: string[]) => setSize(v)))}
          >{size.label}</button>
        ))}
      </div>
      <div className="d-inline-flex btn-group m-2">
        {types.map((type: string, k: number) => (
          <button key={k} className={`btn btn-xs __filtre_btn__ ${typeSelected.includes(type) ? '__filtre_actif__' : ''} `}
                  onClick={() => handleSelect(type, [...typeSelected], ((v: string[]) => setType(v)))}
          >{I18nService._(type, 'equipment.type')}</button>
        ))}
      </div>
    </form>
  );
}
