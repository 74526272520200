import {modelHydrator} from "./_modelHydrator";
import {equipmentResultType} from "../../types/ApiResult/EquipmentResultType";
import {equipment} from "../equipment";
import {sizeResultType} from "../../types/ApiResult/SizeResultType";
import {sizeHydrator} from "./sizeHydrator";
import {collectionHydrator} from "./collectionHydrator";
import {TypeEnum} from "../../types/TypeEnum";
import {typeEnumStrategy} from "./strategy/typeEnumStrategy";

export const equipmentHydrator = {
  hydrate: (equipment: equipmentResultType): equipment => {
    return Object.assign({
      equipmentId: equipment.equipmentId,
      label: equipment.label,
      price: equipment.price,
      type: typeEnumStrategy.fromString(equipment.type, TypeEnum.OTHER),
      picture: equipment.picture,
      collection: collectionHydrator.hydrate(equipment.collection),
      sizes: equipment.sizes?.map((r: sizeResultType) => sizeHydrator.hydrate(r)),
    }, modelHydrator.hydrate(equipment))
  }
}