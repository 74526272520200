import React from 'react';
import {news} from "../../models/news";

type InfoClubModalProps = {
  news: news;
  close: () => void;
}

export const InfoClubModal = ({news, close}: InfoClubModalProps) => {
  return (
    <>
      <div className="__modal_container__ d-flex justify-content-center align-items-center">
        <div className="__modal_background__" onClick={() => close()} />
        <div className="__modal_box__ __front_box__ max-w70 max-h80">
          <i className="float-end cursor-pointer fa-solid fa-circle-xmark text-info" onClick={() => close()}/>
          <div>
            <h2 className="__front_info_title__ m-0">{news.title}</h2>
            <hr className="w-50 ms-auto me-auto mt-3 mb-4"/>
          </div>
          <div className="d-flex flex-wrap">
            {news.photo && (
              <div className="col text-center">
                <img src={news.photo} className="__modal_box_img__ img-fluid max-w90 max-h600px" alt={news.title}/>
              </div>
            )}
            {news.text && (
              <div className="col align-justify" dangerouslySetInnerHTML={{__html: news.text}} />
            )}
          </div>
          <p className="m-0 mt-5 text-end">
            <button className="btn btn-sm btn-info" onClick={() => close()}>fermer</button>
          </p>
        </div>
      </div>
    </>
  );
}
