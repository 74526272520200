import React from 'react';

import {ParcoursProfiter} from "../../components/front/parcours/ParcoursProfiter";
import {ParcoursListe} from "../../components/front/parcours/ParcoursListe";

export const Parcours = () => {
  return (
    <>
      <ParcoursProfiter />
      <ParcoursListe />
    </>
  );
}
