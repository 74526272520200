import React from 'react';
import {BoxTitle} from "./BoxTitle";

export const Reglement = () => {
  return (
    <>
      <div className="__front_box__">
        <BoxTitle title="Règlement intérieur"/>
          <div className="text-center">
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">1 –But de l’association <span className="c-team-chartres">C’Team Chartres</span></p>
              <p className="__front_reglement_line__"><span className="c-team-chartres">C’Team Chartres</span> est une association sportive sans but lucratif, composée d’adhérents
                passionnés
                par le cyclisme et unis autour de valeurs sportives et humaines communes : engagement, convivialité, tolérance et partage.</p>
              <p className="__front_reglement_line__">Notre « terrain de jeu » étant le réseau routier, la pratique du vélo au sein de notre club cycliste exige un minimum
                d’expérience
                de la route et le respect des règles. Cette activité exige aussi une bonne forme physique (par exemple, la capacité à suivre un groupe sur un parcours de 90 km à
                28km/h de moyenne).</p>
              <p className="__front_reglement_line__">En adhérant à <span className="c-team-chartres">C’Team Chartres</span> vous prenez connaissance et vous vous engagez à
                respecter
                ce
                règlement intérieur ainsi que la charte éthique du club.</p>
              <p className="__front_reglement_line__">Vous trouverez dans ces documents (consultables sur le site de notre club (<a href="/">C’Team Chartres</a>) les principes qui
                régissent notre association et particulièrement la manière de pratiquer le cyclisme ensemble.</p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">2 – Cotisations et licences</p>
              <p className="__front_reglement_line__">Pour rejoindre <span className="c-team-chartres">C’Team Chartres</span>, vous devez souscrire une licence UFOLEP
                (obligatoire).
                Son
                montant est fixé annuellement par le bureau. La licence est souscrite pour une période allant du 1er Août au 30 septembre. <span className="text-danger">Au 1er octobre si la licence n’est pas renouvelée, vous ne ferez plus partie du club et vous ne serez plus couvert par l’assurance.</span>
              </p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">3 – La Tenue du <span className="c-team-chartres">C’Team Chartres</span></p>
              <p className="__front_reglement_line__">La tenue officielle du club devra être portée prioritairement lors de chaque sortie ainsi que lors de participation à des
                événements
                extérieurs au club (cyclosportives, courses …) Sans le port du maillot de l’année, aucune participation éventuelle aux frais d’inscription par le club ne sera
                effectuée</p>
              <p className="__front_reglement_line__">Les raisons de cette injonction sont multiples : signe d’appartenance au même club dont nous avons toutes les raisons d’être
                fiers,
                meilleure reconnaissance sur la route, sécurité favorisée et enfin respect pour nos sponsors.</p>
              <p className="__front_reglement_line__">Le choix de couleurs claires, fluorescentes et brillantes dans le design du maillot est également en lien direct avec des
                raisons de
                sécurité car nos maillots sont ainsi très visibles de tous notamment ce qui compte le plus, par les autres usagers de la route. C’est une raison supplémentaire - et
                non des moindres - devant encourager chacun à porter le maillot du club.</p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">4 – Le bureau</p>
              <p className="__front_reglement_line__">La direction de l’association est collégiale et est assurée par un bureau composé de 7 membres en 2024, élus pour une durée
                déterminée.</p>
              <p className="__front_reglement_line__">Le bureau est composé d’un président, assisté par un trésorier, un secrétaire, de deux responsables technique, un responsable
                des
                parcours, un responsable des équipements.</p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">5– Etre adhérent au <span className="c-team-chartres">C’Team Chartres</span></p>
              <p className="__front_reglement_line__">L’adhérent doit être à jour de sa cotisation. Chaque adhérent peut consulter le règlement intérieur et la charte éthique sur
                le
                site
                <a href="/">C’Team Chartres</a></p>
              <p className="__front_reglement_line__">La participation d’un non-adhérent aux sorties prévues par le club, est autorisée au titre de découverte. Passé un nombre de 3
                sorties (au total et non par année), il devra se licencier et régler la cotisation annuelle ou souscrire à une carte de membre en apportant la preuve lors de sa
                souscription de son adhésion dans un autre club. Une personne non licenciée ne peut souscrire une carte de membre : il devra dans ce cas souscrire une licence. Pour
                les mineurs, une autorisation parentale signée est obligatoire.</p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">6 – La pratique du Cyclisme</p>
              <p className="__front_reglement_line__">
                Le club met à la disposition de ses membres :<br/>
                - 2 sorties hebdomadaires publiées sur le site Internet du club<br/>
                - des sorties publiées sur le calendrier du site<br/>
                - des stages éventuels<br/>
                - une tenue club à tarif préférentiel<br/>
                - des remboursements d’épreuves selon des modalités délibérées annuellement<br/>
                - des informations régulières relatives à la vie du club et à la pratique du vélo de route<br/>
              </p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">7 – Participation à la vie du club et convivialité</p>
              <p className="__front_reglement_line__">Chaque adhérent est concerné par la vie du club et la réussite de ses organisations :</p>
              <p className="__front_reglement_line__">- En s’engageant moralement à apporter sa contribution dans l’année aux différentes manifestations dont lesquelles le club est
                impliqué ou dont il assure la maitrise d’oeuvre. Cela signifie participer aux manifestations nécessitant la présence d’adhérents du club ou aider d’une manière ou
                d’une autre à la meilleure marche de l’association (organisation de sorties exceptionnelles, conseils pour les parcours, aides concrètes parfois demandées, prises
                de responsabilités diverses, etc.).</p>
              <p className="__front_reglement_line__">
                - En contribuant aussi bien sûr à la vie du club, ce qui peut conduire, par exemple :<br/>
                - à participer aux réunions durant lesquelles de nombreuses informations sont données. Ces réunions contribuent à la bonne convivialité et permettent des échanges
                entre les différents adhérents présents qui apprennent ainsi à mieux se connaitre et à se transmettre connaissances et informations diverses,<br/>
                - à apporter des réflexions ou points de vue permettant d’améliorer l’organisation ou la bonne marche du club, le tout de façon constructive et bienveillante.<br/>
              </p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">8 – La sécurité</p>
              <p className="__front_reglement_line__">
                En sorties individuelles ou en groupes :<br/>
                – Respect du code de la route<br/>
                – Respect de la signalisation <br/>
                – Port du casque obligatoire<br/>
                – Ecouteurs proscrits<br/>
              </p>
              <p className="__front_reglement_line__">Chaque adhérent est responsable de l’entretien de son vélo et des accessoires de dépannage (pompe, outils, chambres à air)</p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">9 – Les sorties et les circuits</p>
              <p className="__front_reglement_line__">L’adhérent devra respecter le parcours posté sur le site du club et dans la mesure du possible, charger sur son GPS la trace
                GPX
                mise à sa disposition également sur le site à chaque sortie.</p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">10 – Les assurances</p>
              <p className="__front_reglement_line__">Le licencié bénéficie des garanties qui sont automatiquement acquises par sa licence UFOLEP. Il est possible de souscrire des
                garanties supplémentaires lors de la prise de licence en se référant annuellement aux dispositions proposées par la fédération et son assureur (AXA). A noter qu’en
                cas d’accident, en aucun cas, la responsabilité du club ne pourra être engagée pour un pratiquant non-adhérent au <span
                  className="c-team-chartres">C’Team Chartres</span></p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">11 – La charte</p>
              <p className="__front_reglement_line__">Chaque adhérent prend connaissance de la charte éthique postée sur le site du club <span
                className="c-team-chartres">C’Team Chartres</span> (cteamchartres.fr) ADN de notre club et principes éthiques qui régissent la vie de notre association et plus
                particulièrement, la manière de pratiquer le cyclisme ensemble.</p>
            </div>
            <div className="__front_reglement_block__">
              <p className="__front_reglement_titre__">12 – Droit à l’image</p>
              <p className="__front_reglement_line__">Sauf refus personnel, signifié par écrit au Bureau, l’adhérent autorise toute utilisation des photographies et vidéos prises
                au
                cours des activités de l’association. Ces photos ou vidéos peuvent parfois être utilisées dans divers supports d’informations : presse, site Internet, etc.</p>
            </div>
          </div>

      </div>
    </>
  );
}
