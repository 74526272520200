import React, {useEffect} from 'react';
import {CalendrierParticiper} from "../../components/front/calendrier/CalendrierParticiper";
import {CalendrierBox} from "../../components/front/calendrier/CalendrierBox";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {season} from "../../models/season";
import {fetchData as fetchSeason} from "../../redux/season_reducer";
import {CalendrierBoxLoader} from "../../components/front/loader/CalendrierBoxLoader";

export const Calendrier = () => {
  const dispatch = useAppDispatch();

  const loading: boolean = useAppSelector((state) => state.season.loading);
  const seasons: season[] = useAppSelector((state) => state.season.data);

  useEffect(() => {
    dispatch(fetchSeason())
  }, [dispatch]);

  return (
    <>
      {loading && (
        <CalendrierBoxLoader/>
      )}
      {!loading && (
        <CalendrierBox seasons={seasons}/>
      )}
      <CalendrierParticiper/>
    </>
  );
}