import React from 'react';
import {Reglement as ReglementContent} from "../../components/front/Reglement";

export const Reglement = () => {
  return (
    <>
        <ReglementContent />
    </>
  );
}
