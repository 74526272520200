import React from 'react';

export type BoxTitleProps = {
  title: string;
}

export const BoxTitle = ({title}: BoxTitleProps) => {
  return (
    <>
      <div className="__box_title_container__">
        <hr/>
        <h2 className="__box_title_h2__">{title}</h2>
        <hr/>
      </div>
    </>
  );
}
