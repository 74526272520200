import {modelHydrator} from "./_modelHydrator";
import {size} from "../size";
import {sizeResultType} from "../../types/ApiResult/SizeResultType";
import {typeEnumStrategy} from "./strategy/typeEnumStrategy";
import {TypeEnum} from "../../types/TypeEnum";

export const sizeHydrator = {
  hydrate: (size: sizeResultType): size => {
    return Object.assign({
      sizeId: size.sizeId,
      label: size.label,
      order: size.order,
      type: typeEnumStrategy.fromString(size.type, TypeEnum.OTHER),
      stock: size.stock,
    }, modelHydrator.hydrate(size))
  }
}