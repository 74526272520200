export class StorageService {
  private static _storage?: Storage;
  private static encoded: boolean = true;

  private static get storage(): Storage {
    if (StorageService._storage === undefined) {
      StorageService._storage = localStorage;
    }

    return StorageService._storage;
  }

  private static _encode(data: unknown, encoded: boolean): string {
    let dataEncoded = JSON.stringify(data);

    if (encoded) {
      dataEncoded = window.btoa(dataEncoded);
    }

    return dataEncoded;
  }

  private static _decode(json: string, encoded: boolean): {} {
    let data = undefined;

    if (json !== '' && json !== null) {

      if (encoded) {
        json = window.atob(json);
      }

      data = JSON.parse(json);
    }

    return data;
  }

  public static hasItem(key: string) {
    return StorageService.storage.getItem(key) !== null;
  }

  public static getItem(key: string) {
    return StorageService._decode(StorageService.storage.getItem(key) as string, StorageService.encoded);
  }

  public static setItem(data: unknown, key: string) {
    StorageService.storage.setItem(key, StorageService._encode(data, this.encoded));
  }

  public static removeItem(key: string) {
    StorageService.storage.removeItem(key);
  }

}
