import React from 'react';
import ContentLoader from 'react-content-loader';

export const AlbumBoxPhotosLoader = () => {
  return (
    <div className="w-100 h-100">
      <ContentLoader
        speed={3}
        width="100%"
        height="100%"
        viewBox="0 0 200 200"
        backgroundColor="#e1e1ff"
        foregroundColor="#8b8bf9"
      >
        <rect x="0" y="0" rx="3" ry="3" width="200" height="200"/>
      </ContentLoader>
    </div>
  );
}
