import {participantResultType} from "../../types/ApiResult/ParticipantResultType";
import {participant} from "../participant";

export const participantHydrator = {
  hydrate: (participant: participantResultType): participant => {
    return {
      participantId: participant.participantId,
      name: participant.name,
      rank: participant.rank,
      raceId: participant.race?.raceId,
    }
  }
}