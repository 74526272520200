import React from 'react';

export type ErrorProps = {
  error: unknown
}

export const Error = ({error}: ErrorProps) => {
  const error404: boolean = (error !== null && typeof error === 'object' && 'status' in error && error.status === 404);

  console.log(error);

  return (
    <div className="__front_box__ p-5 text-center">

      {error404 && (
        <>
          <h2 className="h2 p-5 m-5">
            Page est introuvable.
          </h2>
        </>
      )}
      {!error404 && (
        <>
          <h2 className="h2 p-5 m-5">
            Une erreur est survenue.
          </h2>
        </>
      )}

    </div>
  );
}
