import {createdAtType} from "../../types/ApiResult/ApiResultType";
import {model} from "../_model";

export const modelHydrator = {
  hydrate: (model: createdAtType): model => {
    return {
      createdAt: model.createdAt,
      updatedAt: model.updatedAt
    }
  }
}