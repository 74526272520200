import React from 'react';
import logo from '../../assets/images/c_team_chartres.jpg';
import {NavLink} from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <footer className="mt-4">
        <div className="container d-flex flex-wrap align-items-center p-2">
          <div className="col-12 col-md-4 text-center mt-2 mb-2">
            <a href="http://www.velo101.com/" target="_blank" rel="noreferrer">Le site officiel du vélo</a><br/><br/>
            Copyright &copy; <span className="c-team-chartres">C’Team Chartres</span><br/>
            <small style={{fontStyle: 'italic'}}>
              (créé par <a href="https://www.jtoile.fr" target="_blank" rel="noreferrer">Jtoile</a>)
            </small>
          </div>

          <div className="col-12 col-md-4 text-center mt-2 mb-2">
            <NavLink to="/info-club" title="Info du club" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>Info du club</NavLink><br/>
            <NavLink to="/partenaires" title="Nos Partenaires" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>Nos Partenaires</NavLink><br/>
            <NavLink to="/reglement" title="Règlement intérieur" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>Règlement intérieur</NavLink><br/>
            <br/>
            <NavLink to="/admin" title="Nous contacter">Espace membre <i className="fa fa-sign-in"></i></NavLink>
          </div>

          <div className="col-12 col-md-4 text-center mt-2 mb-2">
            <div className="d-flex flex-wrap">
              <div className="col-12 col-lg-8">
                Le président :<br/>
                NAVEAU Jean-Yves<br/>
                <i className="fa-solid fa-phone me-2"></i>06.10.39.01.00<br/>
              </div>
              <div className="d-none d-md-block col-lg-4 mb-1">
                <img src={logo} className="img-fluid" alt="logo"/>
              </div>
            </div>
            <div className="text-center">
              N° Affiliation UFOLEP :
              <span className="ms-1 bold">28&nbsp;085&nbsp;090</span>
            </div>
          </div>
        </div>
      </footer>
      {/*Youhou<br/>*/}
      {/*Youhou<br/>*/}
      {/*Youhou<br/>*/}
      {/*Youhou<br/>*/}
      {/*Youhou<br/>*/}
      {/*Youhou<br/>*/}
      {/*Youhou<br/>*/}
      {/*Youhou<br/>*/}
    </>
  );
}
