import {TypeEnum} from "../../../types/TypeEnum";

export const typeEnumStrategy = {
  isInEnum: (value: any): boolean => {
    return Object.values(TypeEnum).includes(value);
  },

  fromString: (value: string, defaultValue?: TypeEnum): TypeEnum | undefined => {
    if (typeEnumStrategy.isInEnum(value)) {
      return value as TypeEnum;
    }

    return defaultValue;
  }
}