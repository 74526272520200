const _lang: { [key: string]: { [key: string]: string } } = {
  '_': {},
  'equipment.type': {
    'body': 'Corps',
    'foot': 'Pied',
    'other': 'Autre'
  },
};

export class I18nService {
  public static _(key: string, context?: string): string {
    context = context ?? '_';

    return _lang[context][key] ?? key;
  }
}
