import React from 'react';
import {useForm} from "react-hook-form";
import {login as authLogin} from "../../redux/auth_reducer";
import {useAppDispatch} from "../../redux/store";

type FormDataType = {
  username: string,
  password: string
}

export const LoginForm = () => {
  const dispatch = useAppDispatch();

  const {register, handleSubmit} = useForm<FormDataType>();

  const onSubmit = (data: FormDataType) => {
    dispatch(authLogin({
      username: data.username,
      password: data.password
    }));
  }

  return (
    <>
      <form className="p-3" method="post" onSubmit={handleSubmit(onSubmit)}>
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1"><i className="fa-regular fa-user"></i></span>
          <input type="text" className="form-control" placeholder="login" {...register('username')} />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-key"></i></span>
          <input type="password" className="form-control" placeholder="Mot de passe" {...register('password')} />
        </div>
        <div className="text-center">
          <span className="cursor-pointer underline text-primary">Mot de passe oublié ?</span>
        </div>

        <div className="__form-login_button__">
          <button className="btn btn-primary mt-3 bold">Connexion</button>
          <a href="/" className="btn btn-warning btn-sm mt-2 text-light">Retour</a>
        </div>
      </form>
    </>
  );
}
