import React from 'react';
import {Modal, Button} from "react-bootstrap";
import {photo} from "../../../models/photo";

export type PhotoModalProps = {
  photo: photo,
  close: () => void,
  handle: (i: number) => void,
}

export const PhotoModal = ({photo, close, handle}: PhotoModalProps) => {
  return (
    <>
      <Modal show={true} centered className="__modal__" dialogClassName="__modal_dialog__">
        <Modal.Body className="text-center">
          <Button
            variant="warning"
            onClick={close}
            className="position-absolute btn-sm text-light"
            style={{ right: '10px', top: '10px', zIndex: 1 }}
          >
            &times;
          </Button>
          <img src={photo.src} alt={photo.photoId} className="img-fluid img-thumbnail" onClick={() => handle(+1)} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between border-0">
          <Button variant="warning" className="btn-sm text-light" onClick={() => handle(-1)}>
            Précédent
          </Button>
          <Button variant="warning" className="btn-sm text-light" onClick={() => handle(+1)}>
            Suivant
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}