import React from 'react';
import {BoxTitle} from "../BoxTitle";
import open_runner_long from '../../../assets/images/open_runner_long.png';
import {route} from "../../../models/route";
import {step} from "../../../models/step";
import DateService from "../../../service/DateService";

export type ParcoursBoxProps = {
  route: route
};

export const ParcoursBox = ({route}: ParcoursBoxProps) => {
  return (
    <>
      <div className="__front_box__ h100 d-flex flex-column">
        <BoxTitle title={DateService.toLocaleString(route.rideDate, {weekday: 'long', day: '2-digit', month: 'long', year: 'numeric'})}/>

        <div className="mb-auto">
          <span className="underline bold me-1">Rendez-vous</span>
          à {DateService.getHour(route.meetHour)}h{DateService.getMinute(route.meetHour)} {route.meetPlace}, départ
          à {DateService.getHour(route.startHour)}h{DateService.getMinute(route.startHour)}.

          <br/><br/>
          <span className="underline bold">
            {route.circuit.label} - {route.circuit.distance}&nbsp;km :
          </span><br/>
          {route.circuit.steps.map((step: step, index: number) => (
            <span key={index}>
              {`${index !== 0 ? ' / ' : ''}${step.label}`}
            </span>
          ))}
        </div>
        {route.circuit.openRunner && (
          <div className="mt-3 text-end">
            <a target="_blank" rel="noreferrer" href={route.circuit.openRunner} title="Visualisez sur open runner">
              <img alt="Open Runner" src={open_runner_long} className="img-responsive max-w150px"/>
            </a>
          </div>
        )}
      </div>
    </>
  );
}
