export default class DateService {
  private static date(date: Date|string): Date {
    if (! (date instanceof Date)) {
      if (null !== date.match(/^([0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2})$/)) {
        date = `1970-01-01 ${date}`
      }

      date = new Date(date);
    }

    return date;
  }

  public static getYear(date: Date|string): string {
    return `${this.date(date).getFullYear()}`;
  }

  public static getMonth(date: Date|string): string {
    let month: number | string = this.date(date).getMonth() + 1;

    if (month < 10) {
      month = '0' + month;
    }

    return `${month}`;
  }

  public static getDay(date: Date|string): string {
    let day: number | string = this.date(date).getDate();

    if (day < 10) {
      day = '0' + day;
    }

    return `${day}`;
  }

  public static getHour(date: Date|string): string {
    // console.log(date);
    let hour: number | string = this.date(date).getHours();

    if (hour < 10) {
      hour = '0' + hour;
    }

    return '' + hour;
  }

  public static getMinute(date: Date|string): string {
    let minute: number | string = this.date(date).getMinutes();

    if (minute < 10) {
      minute = '0' + minute;
    }

    return '' + minute;
  }

  public static getSeconde(date: Date|string): string {
    let second: number | string = this.date(date).getSeconds();

    if (second < 10) {
      second = '0' + second;
    }

    return '' + second;
  }

  public static getDate(date: Date|string): string {
    const year: string = this.getYear(date);
    const month: string = this.getMonth(date);
    const day: string = this.getDay(date);

    return `${year}-${month}-${day}`;
  }

  public static getDateTime(date: Date|string): string {
    const d: string = this.getDate(date);
    const h: string = this.getHour(date);
    const m: string = this.getMinute(date);
    const s: string = this.getSeconde(date);

    return `${d} ${h}:${m}:${s}`;
  }

  public static toLocaleString(date: Date|string, optionDate: object): string {
    return this.date(date).toLocaleString('fr-FR', optionDate);
  }

  public static compare(d1: Date|string, d2: Date|string): number {
    d1 = this.date(d1);
    d2 = this.date(d2);

    let result: number = 1;

    const d1Time: number = d1.getTime();
    const d2Time: number = d2.getTime();

    if (d1Time === d2Time) {
      result = 0;
    } else if (d1Time < d2Time) {
      result = -1;
    }

    return result;
  }

  public static past(d: Date|string): boolean {
    return this.compare(d, new Date()) !== 1;
  }
}
