import {ApiService} from "./ApiService";
import {authResultType} from "../types/ApiResult/AuthResultType";
import {StorageService} from "./StorageService";

export class AuthService {
  public static async loginByUsernamePassword(username: string, password: string)
  {
    const data = {
      login: username,
      password: password
    };

    const {access_token, refresh_token}: authResultType = await ApiService.post('/auth/login', { param: () => data }) as authResultType;

    StorageService.setItem(refresh_token, 'refreshToken');

    return access_token;
  }

  public static async loginByRefreshToken()
  {
    const data = {
      refresh_token: StorageService.getItem('refreshToken')
    };

    const {access_token, refresh_token}: authResultType = await ApiService.post('/auth/refresh', { param: () => data }) as authResultType;

    StorageService.setItem(refresh_token, 'refreshToken');

    return access_token;
  }
}
