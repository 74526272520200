import React, {useEffect, useState} from 'react';
import {season} from "../../../models/season";
import {race} from "../../../models/race";
import {calendrierFiltersType} from "./CalendrierBox";
import DateService from "../../../service/DateService";

export type CalendrierListeProps = {
  season: season;
  filters: calendrierFiltersType;
  setRace: (r: race) => void;
}

export const CalendrierListe = ({season, filters, setRace}: CalendrierListeProps) => {
  const [races, setRaces] = useState([] as race[]);
  const [hasCurrent, setHasCurrent] = useState(false);

  const registration = (race: race) => {
    return !race.cancel && race.startRegistration !== undefined && DateService.past(race.startRegistration);
  }

  useEffect(() => {
    if (season.races === undefined) {
      setRaces([]);
      return;
    }

    const now = new Date();
    now.setHours(0, 0, 0);

    let current: boolean = false;

    setRaces(season.races.filter((r: race) => {
      const compare = DateService.compare(r.rideDate, now);

      current = current || compare !== -1;

      return !(
        (compare === -1  && filters.past !== true) ||
        (filters.ranking && !r.ranking) ||
        (filters.ramble && r.ranking) ||
        (filters.paid && !r.paid) ||
        (filters.subsidized && !r.subsidized)
      );
    }));

    setHasCurrent(current);
  }, [setRaces, filters, season]);

  return (
    <table className="table table-responsive table-striped">
      <thead>
      <tr>
        <th colSpan={7}>{hasCurrent ? 'Current' : 'Not current'}</th>
      </tr>
      <tr>
        <th colSpan={4}/>
        <th>Payée</th>
        <th>Subventionnée</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      {races.map((race: race, i: number) => (
        <tr key={i}>
          <td className="text-end">
            {registration(race) && (
              <i className="fa fa-ticket text-primary cursor-pointer" onClick={() => setRace(race)}/>
            )}
          </td>
          <td className="min-w120px text-center">{DateService.toLocaleString(race.rideDate, {weekday: 'short', day: '2-digit', month: 'short'})}</td>
          <td>
            {race.ranking ? (<i className="fa-solid fa-flag-checkered"/>) : (<i className="fa-solid fa-person-biking"/>)}
          </td>
          <td>{race.label}</td>
          <td className="text-center">{race.paid ? (<i className="fa-solid fa-check"></i>) : ''}</td>
          <td className="text-center">{race.subsidized ? (<i className="fa-solid fa-check"></i>) : ''}</td>
          <td>
            {race.link && (
              <a href={race.link} target="_blank" rel="noreferrer"><i className="fa-solid fa-circle-info"/></a>
            )}
          </td>
        </tr>
      ))}
      </tbody>
    </table>
  )
}
