import {modelHydrator} from "./_modelHydrator";
import {news} from "../news";
import {newsResultType} from "../../types/ApiResult/NewsResultType";

export const newsHydrator = {
  hydrate: (news: newsResultType): news => {
    return Object.assign({
      newsId: news.newsId,
      title: news.title,
      text: news.text,
      photo: news.photo,
      order: news.order,
      enabled: news.enabled === 1,
    }, modelHydrator.hydrate(news))
  }
}