import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {weatherType} from "../types/WeatherType";
import {WeatherService} from "../service/WeatherService";

export const fetchData = createAsyncThunk('get-weather', async(_, thunkAPI): Promise<any> => {
  const state: state = (thunkAPI.getState() as RootState).weather;
  const delay: number = 30 * 60 * 1000; // 30min

  if ((Date.now() - state.init) < delay) {
    return undefined;
  }

  return await WeatherService.find('Chartres');
})

interface state {
  init: number;
  loading: boolean;
  data?: weatherType
}

const initialState: state = {
  init: 0,
  loading: false,
  data: undefined,
}

export const weatherSlice = createSlice({
  name: "weather",
  initialState,
  reducers: {},
  extraReducers: (builder): void => {
    builder.addCase(fetchData.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.data = action.payload;
        state.init = Date.now();
      }

      state.loading = false;
    })
    builder.addCase(fetchData.rejected, (state) => {
      state.loading = false;
    })
  }
});

export default weatherSlice.reducer;