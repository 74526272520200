import React from 'react';
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';

export type MapProps = {
  position: {
    lat: number;
    lng: number;
  };
  size: {
    width: string;
    height: string;
  };
  zoom?: number;
  markers?: {
    lat: number;
    lng: number;
  }[]
}

export const Map = ({position, size, zoom, markers}: MapProps) => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAxMoTvHUc7mEX20ZQp1E4A5PvHyY1l99Q"
  });

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={size}
          center={position}
          zoom={zoom ?? 14}
        >
          {markers?.map((marker, i) => (
            <Marker position={marker} key={i} />
          ))}
        </GoogleMap>
      )
      }
    </>
  );
}
