import React from 'react';
import ContentLoader from 'react-content-loader';

export const ParcoursBoxLoader = () => {
  return (
    <div className="__front_box__">
      <ContentLoader
        speed={3}
        width="100%"
        height="100%"
        viewBox="0 0 500 180"
        backgroundColor="#e1e1ff"
        foregroundColor="#8b8bf9"
      >
        <rect x="25" y="5" rx="3" ry="3" width="450" height="2" />
        <rect x="110" y="15" rx="3" ry="3" width="280" height="20" />
        <rect x="25" y="43" rx="3" ry="3" width="450" height="2" />
        <rect x="5" y="60" rx="3" ry="3" width="350" height="6" />
        <rect x="5" y="77" rx="3" ry="3" width="150" height="6" />
        <rect x="5" y="90" rx="3" ry="3" width="490" height="6" />
        <rect x="5" y="100" rx="3" ry="3" width="490" height="6" />
        <rect x="5" y="110" rx="3" ry="3" width="490" height="6" />
        <rect x="5" y="120" rx="3" ry="3" width="490" height="6" />
        <rect x="5" y="130" rx="3" ry="3" width="490" height="6" />
        <rect x="5" y="150" rx="3" ry="3" width="180" height="22" />
      </ContentLoader>
    </div>
  );
}
