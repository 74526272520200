import {route} from "../route";
import {modelHydrator} from "./_modelHydrator";
import {circuitHydrator} from "./circuitHydrator";
import {routeResultType} from "../../types/ApiResult/RouteResultType";

export const routeHydrator = {
  hydrate: (route: routeResultType): route => {
    return Object.assign({
      routeId: route.routeId,
      circuit: circuitHydrator.hydrate(route.circuit),
      meetHour: route.meetHour,
      meetPlace: route.meetPlace,
      order: route.order,
      rideDate: route.rideDate,
      startHour: route.startHour,
    }, modelHydrator.hydrate(route))
  }
}