import React from 'react';
import {BoxTitle} from "../BoxTitle";

export const CalendrierParticiper = () => {
  return (
    <div className="__front_box__">
      <BoxTitle title="Pour participer"/>

      <div className="text-center">
        Pour les sorties avec <span className="bold">location d'un véhicule</span> le club exige
        un <span className="bold">minimum de 7 personnes</span>, sinon la location sera annulée.<br/>
        Les participants <span className="underline">s'organiseront entre-eux</span> pour le déplacement.
        <br/><br/><br/>
        Tout particulièrement pour les sorties prises en charge, <span className="bold">le port du maillot
        du club est obligatoire</span> (respect envers les sponsors).
        <br/><br/><br/>
        Les adhérents bénéficient de <span className="bold">2 sorties</span> au choix dans le département
        et <span className="bold">1 sortie</span> hors département.<br/>
        Ils peuvent aussi <span className="underline">participer aux sorties exterieures</span>, suivant les
        places disponibles.
        Ne pas hésiter à <span className="bold">s'inscrire.</span>
        <br/><br/>
        <span className="bold">Il sera possible de s'inscrire</span> en cliquant
        sur <i className="fa fa-ticket text-primary"/> se
        trouvant devant la <span className="underline">dâte correspondante.</span><br/>
        {/*<span className="italic">(x = nombre de participants)</span>*/}
      </div>
    </div>
  );
}
