import React, {useEffect} from 'react';
import {BoxTitle} from "./BoxTitle";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {fetchData as fetchOffice} from "../../redux/post_office_reducer";
import {postOffice} from "../../models/postOffice";
import {BureauLoader} from "./loader/BureauLoader";

type BureauProp = {
  className?: string;
}

export const Bureau = ({className}: BureauProp) => {
  const dispatch = useAppDispatch();

  const postOffice: postOffice[] = useAppSelector((state) => state.postOffice.data);
  const loading: boolean = useAppSelector((state) => state.postOffice.loading);
  const init: number = useAppSelector((state) => state.postOffice.init);

  useEffect(() => {
    dispatch(fetchOffice())
  }, [dispatch]);

  return (
    <div className={`${className} __front_box__`}>
      <BoxTitle title="Composition du bureau"/>
      {loading && (
        <div className="d-flex flex-wrap justify-content-center">
          {[...new Array(6)].map((i: number, index: number) => (
            <div key={index} className={`m-3 border border-radius-0_4 p-3 d-flex flex-column max-w280px w-100`}>
              <BureauLoader/>
            </div>
          ))}
        </div>
      )}

      {init !== 0 && (
        <div className="d-flex flex-wrap justify-content-center">
          {postOffice.map((po: postOffice, i: number) => (
            <div key={i} className={`m-3 border border-radius-0_4 p-3 d-flex flex-column max-w280px w-100`}>
              <div className="text-center border-bottom pb-3 mb-3">
                <img className="img-fluid h150px rounded-3" src={po.avatar} alt={po.name} />
              </div>
              <div className="text-center mb-2">
                <h6 className="mb-0 __front_office_name__">{`${po.name} ${po.firstname}`}</h6>
              </div>
              <div className="mt-auto mb-auto text-center">
                {po.postOffice.map((o: string, j: number) => (
                  <span className="d-block" key={j}>{o}</span>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
