import ContentLoader from "react-content-loader";
import React from "react";

export const CalendrierBoxLoader = () => {
  return (
    <div className="__front_box__ mb-4">
      <ContentLoader
        speed={3}
        width="100%"
        height="100%"
        viewBox="0 0 500 180"
        backgroundColor="#e1e1ff"
        foregroundColor="#8b8bf9"
      >
        <rect x="120" y="5" rx="3" ry="3" width="260" height="18"/>
        <rect x="5" y="40" rx="3" ry="3" width="80" height="10"/>
        <rect x="90" y="40" rx="3" ry="3" width="80" height="10"/>
        <rect x="5" y="60" rx="3" ry="3" width="490" height="12"/>
        <rect x="5" y="75" rx="3" ry="3" width="490" height="12"/>
        <rect x="5" y="90" rx="3" ry="3" width="490" height="12"/>
        <rect x="5" y="105" rx="3" ry="3" width="490" height="12"/>
        <rect x="5" y="120" rx="3" ry="3" width="490" height="12"/>
        <rect x="5" y="135" rx="3" ry="3" width="490" height="12"/>
        <rect x="5" y="150" rx="3" ry="3" width="490" height="12"/>
      </ContentLoader>
    </div>
  );
}