import React from 'react';
import ContentLoader from 'react-content-loader';

export const BureauLoader = () => {
  return (
    <div className="w-100 h-100">
      <ContentLoader
        speed={3}
        width="100%"
        height="100%"
        viewBox="0 0 400 300"
        backgroundColor="#e1e1ff"
        foregroundColor="#8b8bf9"
      >
        <rect x="100" y="10" rx="3" ry="3" width="200" height="150"/>
        <rect x="10" y="195" rx="3" ry="3" width="380" height="1"/>
        <rect x="50" y="220" rx="3" ry="3" width="300" height="20"/>
        <rect x="80" y="260" rx="3" ry="3" width="240" height="10"/>
        <rect x="80" y="280" rx="3" ry="3" width="240" height="10"/>
      </ContentLoader>
    </div>
  );
}
