import React, {useEffect, useState} from 'react';
import {InfoClubBox} from "../../components/front/InfoClubBox";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {news} from "../../models/news";
import {fetchData as fetchNews} from "../../redux/news_reducer";
import {InfoBoxLoader} from "../../components/front/loader/InfoBoxLoader";
import {InfoClubModal} from "../../components/front/InfoClubModal";

export const InfoClub = () => {
  const dispatch = useAppDispatch();

  const loading: boolean = useAppSelector((state) => state.news.loading);
  const news: news[] = useAppSelector((state) => state.news.data);
  const [modal, setModal] = useState(undefined as undefined|news);

  useEffect(() => {
    dispatch(fetchNews())
  }, [dispatch]);

  return (
    <>
      <div className="d-flex flex-wrap align-items-stretch">
        {loading && (
          <>
            {[...new Array(3)].map((i: number, index: number) => (
              <div className="col-sm-4 p-2" key={index}>
                <InfoBoxLoader/>
              </div>
            ))}
          </>
        )}
        {!loading && news.map((n: news, index: number) => (
          <div className="col-sm-12 col-md-4 p-2" key={index}>
            <InfoClubBox news={n} modal={(n) => setModal(n)} />
          </div>
        ))}
        {modal && (
          <InfoClubModal news={modal} close={() => setModal(undefined) }/>
        )}
      </div>
    </>
  );
}
