import {postOfficeResultType} from "../../types/ApiResult/PostOfficeResultType";
import {postOffice} from "../postOffice";

export const postOfficeHydrator = {
  hydrate: (postOffice: postOfficeResultType): postOffice => {
    return {
      officeId: postOffice.office_id,
      memberId: postOffice.member_id,
      label: postOffice.label,
      postOffice: postOffice.post_office,
      name: postOffice.name,
      firstname: postOffice.firstname,
      avatar: postOffice.avatar,
    }
  }
}