import React from 'react';
import {BoxTitle} from "../BoxTitle";

export const ParcoursProfiter = () => {
  return (
    <div className="__front_box__ mb-3">
      <BoxTitle title="Pour profiter des circuits"/>

      <div className="text-center">
        Une licence <span className="c-team-chartres">C’Team Chartres</span> ou une carte de membre honoraire est nécessaire.
        Les personnes qui s'invitent sans remplir une de ces deux conditions sont inopportunes.<br/><br/>
        Lorsqu'un membre du bureau vous demande de prendre une carte de membre honoraire (25€), n'y voyez pas là une agression mais juste un peu d'équité envers ceux qui
        respectent les statuts du
        club.<br/><br/>
        <span className="underline">Les membres honoraires doivent pouvoir justifier d'une licence dans un autre club.</span><br/><br/>
        Nous acceptons volontiers la présence de non licenciés <span className="underline bold">pour un maximum de trois sorties</span>, (NON RENOUVELABLES D'ANNÉE EN ANNÉE)
        après quoi nous leur demandons de prendre une licence et surtout une
        assurance.
      </div>
    </div>
  );
}
