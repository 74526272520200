import React from 'react';
import {NousContacter} from "../../components/front/NousContacter";
import {Bureau} from "../../components/front/Bureau";

export const Contact = () => {
  return (
    <>
      <Bureau className="mb-4" />
      <NousContacter />
    </>
  );
}
