import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ApiQueryParam, ApiService} from "../service/ApiService";
import {RootState} from "./store";
import {collectionResultCollectionType, collectionResultType} from "../types/ApiResult/CollectionResultType";
import {collectionHydrator} from "../models/hydrator/collectionHydrator";
import {collection} from "../models/collection";

export const fetchData = createAsyncThunk('get-collection', async(_, thunkAPI): Promise<any> => {
  const state: state = (thunkAPI.getState() as RootState).collection;

  if (state.init > 0) {
    return undefined;
  }

  const apiFilters: ApiQueryParam = new ApiQueryParam();

  apiFilters.addSort({
    by: 'label',
    direction: 'DESC'
  });

  apiFilters.setCount(50);

  const result: collectionResultCollectionType =  await ApiService.get('/collection', apiFilters) as collectionResultCollectionType;

  return {
    paginate: {
      page: result._page,
      pageCount: result._page_count,
      total: result._total_items
    },
    data: result._embedded.collections.map((collection: collectionResultType) => {
      return collectionHydrator.hydrate(collection);
    })
  };
})

interface state {
  init: number;
  loading: boolean;
  paginate?: {
    page: number;
    pageCount: number;
    total: number;
  }
  data: collection[]
}

const initialState: state = {
  init: 0,
  loading: false,
  paginate: undefined,
  data: []
}

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {},
  extraReducers: (builder): void => {
    builder.addCase(fetchData.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(fetchData.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.paginate = action.payload.paginate;
        state.data = action.payload.data;
        state.init = Date.now();
      }

      state.loading = false;
    })
    builder.addCase(fetchData.rejected, (state) => {
      state.loading = false;
    })
  }
});

export default collectionSlice.reducer;