import {modelHydrator} from "./_modelHydrator";
import {photoResultType} from "../../types/ApiResult/PhotoResultType";
import {photo} from "../photo";
import {albumHydrator} from "./albumHydrator";

export const photoHydrator = {
  hydrate: (photo: photoResultType): photo => {
    return Object.assign({
      photoId: photo.photoId,
      src: photo.src,
      album: albumHydrator.hydrate(photo.album)
    }, modelHydrator.hydrate(photo))
  }
}