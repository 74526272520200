import React, {useEffect, useState} from 'react';
import {season} from "../../../models/season";
import {calendrierFiltersType} from "./CalendrierBox";

export type CalendrierFiltreProps = {
  seasons: season[];
  setFilters: (f: calendrierFiltersType) => void;
}

export const CalendrierFiltre = ({seasons, setFilters}: CalendrierFiltreProps) => {
  const [season, setSeason] = useState(seasons.find((s: season) => s.enabled)?.seasonId ?? '');
  const [ranking, setRanking] = useState(false);
  const [ramble, setRamble] = useState(false);
  const [paid, setPaid] = useState(false);
  const [past, setPast] = useState(false);
  const [subsidized, setSubsidized] = useState(false);

  const handleRamble = () => {
    setRanking(false);
    setRamble(!ramble);
  }

  const handleRanking = () => {
    setRamble(false);
    setRanking(!ranking);
  }

  const handlePaid = () => {
    setSubsidized(false);
    setPaid(!paid);
  }

  const handlePast = () => {
    setPast(!past);
  }

  const handleSubsidized = () => {
    setPaid(false);
    setSubsidized(!subsidized);
  }

  useEffect(() => {
    setFilters({
      seasonId: season === '' ? undefined : season,
      ranking: ranking,
      ramble: ramble,
      paid: paid,
      subsidized: subsidized,
      past: past
    });
  }, [season, ranking, ramble, paid, subsidized, past]);

  return (
    <form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
      <div className="d-flex justify-content-around">
        <div className="col">
          <div className="d-inline-flex btn-group m-1">
            <button className={`btn btn-xs __filtre_btn__ ${ramble ? '__filtre_actif__' : ''}`} onClick={() => handleRamble()}>Randonnée</button>
            <button className={`btn btn-xs __filtre_btn__ ${ranking ? '__filtre_actif__' : ''}`} onClick={() => handleRanking()}>Course</button>
          </div>

          <div className="d-inline-flex btn-group m-1">
            <button className={`btn btn-xs __filtre_btn__ ${paid ? '__filtre_actif__' : ''}`} onClick={() => handlePaid()}>Payée</button>
            <button className={`btn btn-xs __filtre_btn__ ${subsidized ? '__filtre_actif__' : ''}`} onClick={() => handleSubsidized()}>Subventionnée</button>
          </div>

          <div className="d-inline-flex btn-group m-1">
            <button className={`btn btn-xs __filtre_btn__ ${past ? '__filtre_actif__' : ''}`} onClick={() => handlePast()}>Passé</button>
          </div>
        </div>
        <div className="col text-end">
          <div className="d-inline-flex">
            <select className="form-select" value={season} onChange={(e) => setSeason(e.target.value)}>
              { seasons.length === 0 && (
                <option value=''>Choisir une saison</option>
              )}
              {seasons.map((s: season, i: number) => (
                <option key={i} value={s.seasonId}>{s.label}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </form>
  );
}
