import React, {useEffect} from 'react';
import ContentLoader from "react-content-loader";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {fetchData as fetchWeather} from "../../redux/weather_reducer";
import {weatherType} from "../../types/WeatherType";

const direction: { [key: string]: string } = {
  N: 'nord',
  E: 'est',
  S: 'sud',
  O: 'ouest',
  SE: 'sud-est',
  SO: 'sud-ouest',
}

export const MeteoBox = () => {
  const dispatch = useAppDispatch();

  const weather: weatherType|undefined = useAppSelector((state) => state.weather.data);

  const windDir = (dir: string): string => {
    return direction[dir.toUpperCase()] ?? dir;
  }

  useEffect(() => {
    dispatch(fetchWeather())
  }, [dispatch]);

  return (
    <>
      <div className="d-flex justify-content-center align-items-stretch">
        <div className="col d-flex justify-content-center align-items-center">
          {weather && (
            <div>
              <img src={weather.current_condition.icon_big} className="img-fluid max-w60px" alt={weather.current_condition.condition} />
              <br/>
              {weather.current_condition.condition}
            </div>
          )}
          {!weather && (
            <ContentLoader
              speed={3}
              width={100}
              height={70}
              viewBox="0 0 100 70"
              backgroundColor="#e1e1ff"
              foregroundColor="#c8c8ff">
              <circle cx="50" cy="25" r="25"/>
              <rect x="10" y="60" rx="5" ry="5" width="80" height="6" />
            </ContentLoader>
          )}
        </div>
        <div className="col d-flex justify-content-center align-items-center ">
          {weather && (
            <div>
              <p className="font-2 mb-2">{weather?.current_condition.tmp}°</p>
              <i className="fa-solid fa-wind me-1"/>&nbsp;{weather.current_condition.wnd_spd}&nbsp;km/h<br/>
              <i className="fa-solid fa-location-arrow me-1"/>&nbsp;{windDir(weather.current_condition.wnd_dir)}
            </div>
          )}
          {!weather && (
            <ContentLoader
              speed={3}
              width={90}
              height={70}
              viewBox="0 0 90 70"
              backgroundColor="#e1e1ff"
              foregroundColor="#c8c8ff"
            >
              <circle cx="45" cy="15" r="15" />
              <rect x="15" y="50" rx="5" ry="5" width="60" height="6" />
              <rect x="15" y="40" rx="5" ry="5" width="60" height="6" />
            </ContentLoader>
          )}
        </div>
      </div>
    </>
  );
}
