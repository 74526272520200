import axios from "axios";
import {weatherType} from "../types/WeatherType";

export class WeatherService {
  static async find(city: string) {
    const response = await axios.get(`https://www.prevision-meteo.ch/services/json/${city}`);

    return response.data as weatherType;
  }
}
