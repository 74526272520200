import React from 'react';
import {news} from "../../models/news";

type InfoClubBoxProps = {
  news: news;
  modal: (n: news|undefined) => void;
}

export const InfoClubBox = ({news, modal}: InfoClubBoxProps) => {
  const maxLength = 280;

  const truncate = (text: string): string => {
    if (text.length > maxLength) {
      text = text.replaceAll("<br />", "<br/>")
      text = text.slice(0, maxLength);
      text = text.slice(0, text.lastIndexOf(" "));
      text += ' ...';
    }

    return text;
  };

  return (
    <>
      <div className="__front_box__ __front_info__ h100 cursor-pointer" onClick={() => modal(news)}>
        <h2 className="__front_info_title__ m-0">{news.title}</h2>
        <hr className="w-50 ms-auto me-auto mt-3 mb-4"/>
        <div className="__front_info_text__" dangerouslySetInnerHTML={{__html: truncate(news.text ?? '')}}/>
        {news.photo && (
          <div className="text-center">
            <img src={news.photo} className="__front_info_photo__ img-fluid mt-2" alt={news.title}/>
          </div>
        )}
      </div>
    </>
  );
}
